<template>
  <div class="page">
    <calc-list-item title="基本养老金计算" subtitle="企业职工基本养老金计算" num="79"
                       @click.native="$router.push({ name: 'BasicAnnuityCalcPage' })"></calc-list-item>
    <calc-list-item title="个人养老保险费补缴" subtitle="个人参保补缴计算" num="119"
                       @click.native="$router.push({ name: 'PensionSupplementPersonalPage' })"></calc-list-item>
    <calc-list-item title="单位养老保险费补缴" subtitle="单位参保补缴计算" num="59"
                       @click.native="$router.push({ name: 'PensionSupplementCompanyPage' })"></calc-list-item>
    <calc-list-item title="在职人员死亡待遇" subtitle="在职人员死亡待遇退费计算" num="99"
                       @click.native="$router.push({ name: 'AnnuityReturnOnJobDiePage' })"></calc-list-item>
    <calc-list-item title="领取待遇人员死亡待遇" subtitle="领取待遇人员死亡待遇退费计算" num="79"
                       @click.native="$router.push({ name: 'AnnuityReturnRetireDiePage' })"></calc-list-item>
  </div>
</template>

<script>
import CalcListItem from '@/views/calc/components/CalcListItem'
export default {
  components: {
    CalcListItem
  }
}
</script>

<style scoped>
.page {
  padding: 20px 24px ;
  background: #F9FCFF;
  min-height: 100vh;
}

</style>